@media (max-width:1400px) {
    .inner-login-box {
        padding: 50px 0px;
    }

    .left-login-banner {
        width: 41%;
    }

    .left-login-banner img {
        width: 100%;
        max-width: 384px;
        object-fit: cover;
    }

    .right-banner-part {
        width: 50%;
        max-width: 395px;
    }

    .login-box-inner-wrap {
        padding: 71px 30px 40px 30px;
        overflow-y: scroll;
        height: 575px;
        scrollbar-width: none;
    }

    .sign-up-text {
        padding-top: 93px;
    }

    .center-align-box {

        height: auto;
    }

    .add-car-page {
        height: 534px;
        overflow-y: scroll;
        scrollbar-width: thin;
    }
}