@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: Montserrat, sans-serif !important;
}

.sign-banner-part {
    background: rgba(33, 56, 126, 0.06);
    height: 100vh;
}

.login-logo {
    text-align: center;
}

.center-align-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-width: 838px;
    margin: auto;
}

.left-login-banner {
    width: 50%;
}

.right-banner-part {
    width: 50%;
}

.login-box-inner-wrap {
    max-width: 550px;
    margin: auto;
    padding: 164px 40px 40px 40px;
    border-radius: 0px 24px 24px 0px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
    overflow-y: scroll;
    height: 700px;
    scrollbar-width: none;
}

.login-box-inner-wrap h2 {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.6px;
}

.login-box-inner-wrap p {
    font-size: 15px;
    font-weight: 400;
    color: #6E6B78;
    font-family: "Public Sans", sans-serif;
}

.login-box-inner-wrap form {
    margin-top: 35px;
}

.login-box-inner-wrap label {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
}

.login-box-inner-wrap input {
    background: #fff;
    border-radius: 6px;
    height: 42px;
}

.login-box-inner-wrap input:focus {
    box-shadow: none;
    border-color: #D1D0D4;
}

.form-select:focus {
    border-color: #dee2e6 !important;
    outline: 0;
    box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
    color: #ACAAB1;
    font-family: "Public Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    /* 120% */
    letter-spacing: 0.3px;
}



.remember-check {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}

.remember-check input {
    height: 16px;
    width: 16px;
}

.login-box-inner-wrap input {
    /* height: 42px; */
    border-radius: 6px;
    border: 1px solid #E5E5E5;
    background: #FFF;
}

.login-box-inner-wrap input::placeholder {
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.login-box-inner-wrap label {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
}

.forgot {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.pass-rember-line {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 16px;
    gap: 15px;
}

.submit {
    margin-top: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 42px;
    text-decoration: none;
    margin: auto;
    border-radius: 7px;
    border-radius: 6px;
    background: #21387E;
}

button.submit {
    outline: none;
    border: none;
}


.forgot-btn {
    margin-top: 30px !important;
}

.sign-up-text p {
    color: #696969;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}

.sign-up-text p a {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.sign-up-text {
    text-align: center;
    padding-top: 150px;
}

.pass-eys {
    position: relative;
}

.pass-eys img {
    position: absolute;
    top: 12px;
    right: 19px;
    cursor: pointer;
}

/* sign up  */
.sign-up-box {
    padding: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.terms-conditions p {
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px;
}

.terms-conditions p span {
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-decoration-line: underline;
}

.btn-next .submit {
    margin-top: 30px !important;
}

.login-text {
    text-align: center;
    padding-top: 18px;
}

/* end */

/* opt page */
.otp-varification-page,
.congratulation-page,
.add-car-page {
    margin: auto;
    padding: 40px 40px 40px 40px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
}

.set-otp input {
    width: calc(100% / 8) !important;
    border: 1px solid #D1D0D4;
    outline: none;
    height: 50px !important;
    border-radius: 6px;
}

.set-otp>div {
    justify-content: flex-start !important;
    gap: 10px;
}

.set-otp input[type="text"] {
    color: #21387E;
    font-size: 19px;
    font-weight: 600;
    border: 1px solid #E5E5E5;
}

.center-otp-part {
    max-width: 601px;
    width: 100%;
}

.otp-heading h1,
.congratulation-heading h1,
.add-car-heading h1 {
    color: #121212;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.otp-heading p {
    color: #696969;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
    padding-top: 6px;
}

.otp-heading p span {
    color: #21387E;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.otp-heading {
    margin-bottom: 25px;
}

.otp-varification-page button.submit {
    outline: none;
    border: none;
    margin-top: 30px !important;
}

.reset-time p {
    color: #696969;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
}

.reset-time p span {
    color: #121212;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* end */

/*Upload documents  */
.file-input {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    cursor: pointer;
    padding: 7px 25px;
    border: none;
    border-radius: 6px;
    border: 1px solid #21387E;
    background: #FFF;
    color: #21387E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.business-reg {
    border-radius: 6px;
    border: 1px dashed #E5E5E5;
    background: #FFF;
    padding: 40px;
    margin-bottom: 10px;
    width: 100%;
}

.up-load-doc p {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

.business-reg img {
    width: 241px !important;
    height: 105px;
    object-fit: cover;
    border-radius: 10px;
}



.up-load-doc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

/*end */

/*page setup with side bar */
.main-wrap {
    display: flex;
    height: 100vh;
}

aside {
    width: 300px;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 1;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    transition: 0.2s;
}



.logo-side {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.logo-side img {
    width: 140px;
    margin: 25px 23px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 170px);
    margin-top: 10px;
}

.side-menu .side-btm-space {
    margin-bottom: 5px;
}



.side-menu a p {
    margin: 0;
}

.side-pages {
    padding: 10px 25px;
}

.side-pages p {
    margin: 0px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #ACAAB1;
}

.side-menu a.bar_active {
    color: #ffffff;
}

.side-menu a.bar_active {
    color: #ffffff;
    border-radius: 6px;
    background: #21387E;
    margin: 0px 10px;
    padding: 12px 15px;
}

.side-menu a svg {
    margin-right: 13px;
    width: 22px;
}

.side-menu a {
    display: flex !important;
    padding: 10px 25px;
    text-decoration: none;
    color: #696969;
    font-family: Montserrat;
    font-style: normal;
    line-height: normal;
    font-weight: 500 !important;
    align-items: center;
    font-size: 14px;
}

/* 
.side-menu a {
    color: #000;
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    line-height: normal;
} */

.right-side-cmn {
    width: calc(100% - 300px);
    position: relative;
    margin-left: auto;
    background: #FAFAFA;
    min-height: 100vh;
}



.right-top-bar {
    padding: 12px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

.left-side-bar {
    display: flex;
    align-items: center;
    gap: 10px;
}

.left-side-bar input {
    border: 0 !important;
    background-color: transparent;
    outline: none !important;
}

.notification-icon:before {
    position: absolute;
    content: "";
    background: #E5E5E5;
    height: 34px;
    width: 1px;
    right: -15px;
    top: -4px;
}

.notification-icon {
    position: relative;
}

.dropdown-toggle::after {
    position: absolute;
}

.dropdown-menu.show {
    width: 230px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    border: none;
    padding: 10px;
}

a.logout-btn.dropdown-item {
    color: #F00;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-img-top .dropdown-item:active {
    background: #f8f9fa !important;
    color: #444151 !important;
}

.my-profile a {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.right-side-bar {
    display: flex;
    align-items: center;
    gap: 30px;
    /* width: 100%;
	max-width: 177px; */
}

.user-profile-icon {
    display: flex;
    align-items: center;
    gap: 5px;

}

.user-profile-icon p {
    color: #121212;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0px;
}

.user-img-top button#dropdown-basic {
    background: none;
    border: none;
    padding: 0px;
    /* width: 50px; */
    border-radius: 50%;
    height: 50px;
}

.right-top-bar .user-img-top img {
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.drop-pro-view {
    display: flex;
    gap: 5px;
    align-items: center;
}

.right-top-bar .user-img-top img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.admin-name h3 {
    color: #444151;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin: 0px;
}

.admin-name p {
    font-size: 13px;
    font-weight: 400;
    color: #ACAAB1;
    line-height: 20px;
    margin: 0px;
}

.left-side-bar button {
    border: none;
    outline: none;
}

.user-search {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    margin-top: 21px;
    padding: 20px;
    max-width: 708px;
    margin-left: 9px;
}

.main-heading h2 {
    color: #121212;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
}

.edit-flex-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-profile-image p {
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    margin: 0px;
}

.user-profile-image img {
    width: 80px;
    border-radius: 50%;
    height: 80px;
    object-fit: cover;
}


.user-profile-image {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-info input:focus {
    box-shadow: none;
    border-color: #D1D0D4;
}

.user-info input,
.user-info select {
    color: #121212 !important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 42px;
    border-radius: 6px;
    border: 1px solid rgba(229, 229, 229, 1);
}

.user-info input::placeholder,
.user-info select {
    color: rgba(105, 105, 105, 1) !important;

}

.modal-main-heading h1 {
    color: #121212;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.modal-main-heading {
    text-align: center;
    margin-top: 33px;
}

.updated-modal .modal-header {
    padding: 0px;
    border-bottom: 0px;
}

.updated-modal button.btn-close {
    position: absolute;
    right: 13px;
    top: 10px;
    z-index: 1111;
}

.modal-main-heading p {
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 14px;
}

.updated-modal .modal-footer {
    border: none;
    justify-content: center;
    margin-bottom: 13px;
}

.footer-button button.btn-secondary {
    border-radius: 6px;
    background: #21387E;
    width: 48%;
}


.footer-button button.btn.btn-primary {
    border-radius: 6px;
    border: 1px solid #21387E;
    background: #FFF;
    width: 48%;
    color: #21387E;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.new-popover .popover-arrow {
    display: none !important;
}


.new-popover h3.popover-header {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    border: none;
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px;
    width: 222px;
    cursor: pointer;
}

.div#popover-basic {
    border: none !important;

}

button.btn.btn-secondary.done-modal-btn {
    width: 100%;
}

.delete-opt img {
    cursor: pointer;
}

.input-number {
    position: relative;
}

.new-select-box {
    position: absolute;
    top: 2px;
    left: 2px;
}

.drop-with-number input {
    padding-left: 128px;
}

.new-select-box select {
    border: none;
}

.new-select-box select {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    border-right: 1px solid #E5E5E5;
    border-radius: 0px;
}

/* end */

.center-bank-details {
    max-width: 860px;
    width: 100%;
}

.bank-details-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.user-info {
    width: 100%;
}

.green-icon {
    text-align: center;
}

.text-heading h1 {
    text-align: center;
    color: #121212;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-heading p {
    color: #696969;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin: 0px;
}

button.submit.add-to-cart {
    border-radius: 6px;
    border: 1px solid #21387E;
    background: #FFF;
    color: #21387E;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cart-bottom-btn {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}


.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 0;
}

.dash-image img {
    width: 100%;
    max-width: 525px;
    height: 450px;
}

.dashboard-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh;
}

.bar_active path {
    fill: #fff;
}

.opt-resent {
    display: flex;
    gap: 4px;
}

button.new-danger {
    background: #21387E;
    border: 1px solid #21387E;
}

button.new-secondary {
    background: transparent;
    color: #21387E;
    border: 1px solid #21387E;
}

.new-danger.btn:hover {
    background: #21387E !important;
    border: 1px solid #21387E !important;
}

.new-secondary.btn:hover {
    background: transparent;
    color: #21387E;
    border: 1px solid #21387E;
}

.new-secondary.btn:first-child:active {
    background: transparent;
    color: #21387E;
    border: 1px solid #21387E;
}



/* Congratulations page */
.congratulation-page {
    max-width: 638px;
}

.congratulation-heading>img {
    width: 100.43px;
    height: 100.43px;
    margin-bottom: 25px;

}

.congratulation-heading {
    text-align: center;
}

.congratulation-heading>p {
    font-size: 16px;
    font-weight: 500 !important;
    margin-top: 10PX;
    margin-bottom: 30px;
    color: rgba(105, 105, 105, 1);
}

.done-add-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.done-btn,
.add-cars-btn {
    max-width: 275px;
    /* width: 100%; */
    height: 48px;
    border-radius: 6px;
}

.done-btn {
    background: rgba(33, 56, 126, 1);
    font-size: 16px;
    font-weight: 500;
    color: white;
    height: 38px !important;

}

button.done-btn {
    color: #fff !important;
    border: none;
    outline: none;
    padding: 7px;
    min-width: 100px;
}

.add-cars-btn {
    border: 1px solid rgba(33, 56, 126, 1);
    color: rgba(33, 56, 126, 1);
    font-size: 16px;
    font-weight: 500;
    background-color: white;

}

/* Add Car Page */
.add-btn {
    max-width: 778px;
    width: 100%;
    height: 48px;
    background-color: rgba(33, 56, 126, 1);
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    outline: none;
    border: none;
}

.add-car-page {
    max-width: 858px;
    width: 100%;
    /* height: 703px; */
}

.add-car-heading h1 {
    margin-bottom: 20px;
}

.add-car-input {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
}

.registered-in-input,
.add-car-document-3 {
    width: 48.5% !important;
}

.add-car-btn {
    padding-top: 20px;
    text-align: center;
}


/* Dashboard */
.filters {
    /* margin-left: 10px; */
    background: #fff;
    /* box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%); */
    border-radius: 16px;
    margin-top: 20px;
    box-shadow: 0px 4px 16px 0px #0000000A;

}

.inner-filter-field {
    /* padding-bottom: 5px; */
    padding: 20px;
}

.image-heading-text {
    /* padding: 23px 23px 0px 23px; */
    background: #fff;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.image-heading-text h2,
.vehicle-card>h1 {
    color: #121212;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
    max-width: 200px;
    word-wrap: break-word;
}

.table-responsive {
    overflow-x: auto;
    /* margin: 22px; */
    border: 1px solid #E6E5E7;
    border-radius: 12px;
}

.table-responsive table {
    margin: 0px;
}

.table-responsive thead tr th {
    padding: 14px 20px;
    position: relative;
    border-right: 1px solid #ECECEC;
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #F4F4F4;
    vertical-align: baseline;

}

.table-responsive tbody tr td {
    max-width: 124px;
    padding: 14px 20px;
    border-right: 1px solid #ECECEC;
    vertical-align: middle;
    word-wrap: break-word;

}

.table-responsive tbody tr td:last-child {
    border-right: 0px;
}

.table-responsive tbody tr:last-child {
    border-bottom: transparent;
}

.table-responsive thead tr th:last-child {
    border-right: 0px;
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 10px;


}

.user-profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-id p {
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}

.rating {
    display: flex;
    gap: 2px;
}

.user-view {
    display: flex;
    gap: 7px;
}

.earnings-heading {
    text-align: center;

}

.earnings-heading>tr {
    border-top: 1px solid #ECECEC !important;
    text-align: center;
}

.earnings-heading>tr>th {
    /* min-width: 150px ; */
    text-align: center;
}

.view-all {
    font-size: 14px;
    font-weight: 600;
    color: #21387E;
    text-decoration: none;

}

.dashboard-upper-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    /* padding: 20px; */
}

.dashboard-card {
    background-color: #FDF4E8;
    border-radius: 12px;
    /* height: 100px; */
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.driver-img {
    background-color: #FFD7A6;
    border-radius: 50%;
    max-width: 60px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-card-content h2 {
    font-size: 14px;
    font-weight: 500;
    color: #696969;
}

.dashboard-card-content h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}

.vehicles-card {
    background-color: #E8F7FF;
}

.vehicles-card>.driver-img {
    background-color: #ADE2FF;
}

.earnings-card {
    background-color: #F3F0FF;
}

.earnings-card>.driver-img {
    background-color: #D0C7FF;
}

.active-vehicles-card {
    background-color: #DEFFF3;
}

.active-vehicles-card>.driver-img {
    background-color: #9AEFCF;
}

.drivers-rating {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* -------------------footer----------------- */

.footer-sec p {
    color: #696969;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0px;
}

.footer-sec {
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    padding: 22px;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

/* -------------------Vehicles----------------- */

.search-tab {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    margin: 20px 0px;
}

.input-search {
    width: 94%;
    position: relative;
}

.search-icon {
    position: absolute;
    top: 6px;
    left: 12px;
}

.input-search input {
    padding-left: 36px;
    color: #696969;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.input-search .form-control:focus {
    border-color: inherit !important;
    box-shadow: none !important;
    border: 1px solid #dee2e6 !important;
}

.full-input-width {
    width: 100% !important;
}

.user-search-passengers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px 0px;
}

.toggle-box-top {
    display: flex;
    gap: 5px;
    align-items: center;
}

.toggle-btn {
    cursor: pointer;
}


.toggle-active p {
    color: #21387E !important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.toggle-btn p {
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    display: flex;
    gap: 7px;
    align-items: center;
    padding-right: 24px;
    padding-bottom: 11px;
}

.vehicles-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.add-more-vehicles-btn {
    max-width: 180px;
    width: 100%;
    height: 41px;
    border-radius: 6px;
    border: 1px solid #21387E;
    color: #21387E;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.vehicle-no {
    text-decoration: none;
    color: #121212;

}

/* ----------------Vehicles deatils page------------- */

.remove-vehicle-btn {
    max-width: 180px;
    width: 100%;
    height: 41px;
    border-radius: 6px;
    border: 1px solid #FF0000;
    background-color: white;
    color: #FF0000;
}

.vehicle-card {
    /* padding: 20px; */
}

.vehicle-details {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.vehicle-details-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.vehicle-inner-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.details-img {
    width: 24px;
    height: 24px;
}


.pickup-dropoff {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.user-img>img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;

}

.vehicle-inner-card>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.info-details {
    padding: 15px 0;
}

.info-details p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #696969;
}

.info-details p span {
    color: #121212;
}

.vehicles-btn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.deactivate-vehicle-btn {
    background-color: #21387E;
    color: white;
}

.vehicle-card-content>h2 {
    font-size: 16px;
    font-weight: 600;
    color: #121212;
    margin: 0;

}

.currently-assigned-rating {
    display: flex;
    align-items: center;
    gap: 5px;
}

.total-earnings-card {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 145px; */
}

.total-earnings-card-content>h2 {
    font-size: 20px;
    font-weight: 600;
    color: #121212;
}

.total-earnings-card-content>h3 {
    font-size: 32px;
    font-weight: 600;
    color: #21387E;
}

/* .documents-heading-vehicle-details, .documents-vehicle-details {
	padding: 23px 23px 0px 23px;
} */
.documents-heading-vehicle-details>h2 {
    font-size: 14px;
    font-weight: 600;
    color: #21387E;
    background-color: #21387E1A;
    padding: 10px;
    border-radius: 4px;
}

.documents-vehicle-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-top: 20px;

}

.documment-content .document>img {
    max-height: 195px;
    max-width: 140px;
}

.documment-content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.pickup-dropoff-location>img {
    object-fit: contain;
}

.document-card {
    border: 1px solid #E5E5E5;
    min-height: 261px;
    border-radius: 12px;
}

.document-card .documment-content p {
    font-size: 14px;
    font-weight: 500;
    color: #121212;
    margin: 0;
}

.edit-file-input>label {
    border: none;
    padding-top: 0;
}

.document-card>div {
    display: flex;
    justify-content: end;
    padding: 10px 10px 0px 10px;
}

.edit-document img {
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
}

.table-cmn tr td {
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* margin: 0px; */
}

.add-car-input .document-view>img {
    max-height: 195px;
    max-width: 140px;
}



/* ----------Trip Details----------- */
.trip-details-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.driver-trip-detail-card {
    border: 1px solid #E5E5E5;
    padding: 20px;
    border-radius: 12px;
}

.driver-trip-detail-card>h2 {
    font-size: 16px;
    font-weight: 700;
    color: #696969;
    margin-bottom: 0px;
}

.car-details-content>h2 {
    font-size: 16px;
    font-weight: 700;
    color: #696969;
    /* margin-bottom: 12px; */
}

.car-details-content>h3 {
    font-size: 16px;
    font-weight: 600;
    color: #121212;
    margin: 0;
}

.car-details-content>p {
    font-size: 14px;
    font-weight: 500;
    color: #696969;

}

.cash-span {
    font-size: 20px;
    font-weight: 600;
    color: #696969;

}

.review-cards h2 {
    font-size: 16px;
    font-weight: 600;
    color: #121212;
}

.review-cards {
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.review-para {
    font-size: 14px;
    font-weight: 400;
    color: #696969;
    margin: 0;
}

.filter-btn button {
    border-radius: 6px;
    background: #21387E;
    padding: 9px 19px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    outline: none;
}

.earnings-check {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
}

.earnings-boxs {
    border-radius: 16px;
    background: #21387E;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    box-sizing: border-box;
}

.earnings-boxs p {
    color: var(--Misc-bg-white, #FFF);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.earnings-boxs h2 {
    color: var(--Misc-bg-white, #FFF);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.earnings-heading {
    padding: 0 !important;
}

.earnings-heading {
    padding: 0 !important;
    width: 100px;
}


.platform-fee-card {
    border-radius: 16px;
    border: 1px solid #21387E;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
}

.platform-fee-boxs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 20px 0px;
}

.platform-fee-card p {
    color: #21387E;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.platform-fee-card h2 {
    color: #21387E;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.platform-fee-card h2 span {
    color: #21387E;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.earnings-checks h2 span {
    color: var(--Misc-bg-white, #FFF);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.term-conditions-data p {
    color: #696969;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}


.edit-profile-page .user-info label {
    color: #696969;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.notifications-data .notify-card {
    border-radius: 6px;
    border: 1px solid #E5E5E5;
    padding: 12px;
    margin-bottom: 10px;
}


.notifications-data .notify-card h5 {
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.notifications-data .notify-card p {
    color: #696969;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
}

.notifications-data .notify-card>div {
    color: #696969;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.left-side.hide aside {
    width: 100px;
    transition: 0.2s;
}


.left-side.hide aside .side-menu a p {
    display: none;
}

.left-side.hide .side-menu a {
    justify-content: center;
}

.right-side-cmn.hide {
    width: calc(100% - 100px);
    transition: 0.2s;
}

.left-side.hide .side-menu a svg {
    margin-right: 0;
}


.logo-side .small {
    display: none;
}

.logo-side .small {
    width: 40px;
}

.left-side.hide .logo-side .small {
    display: block;
}

.left-side.hide .logo-side .big {
    display: none;
}



.pagination-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #ECECEC;
    margin-bottom: 50px !important;
}

.showing-user p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #ACAAB1;
    margin: 0px;
}


.pagination-block ul {
    margin: 0px !important;
    gap: 6px;
}

.pagination-block a.page-link {
    background: #EFEEF0;
    border-radius: 6px;
    color: #423F4F;
    font-size: 13px;
    font-weight: 400;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    line-height: 38px;
    justify-content: center;
    padding: 0px;
}

.pagination-block li.page-item a span {
    font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
    background: #21387E;
    border: 1px solid #21387E;
    border-radius: 6px;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    line-height: 38px;
    justify-content: center;
}

.pagination-block .page-link:focus {
    box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
    background: #E8E6FC;
    color: #7367F0;
}

.border-none {
    border: 0 !important;
}




.img-show-pop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* padding: 10px; */
    position: relative;
}

.img-show-pop>div {
    display: flex;
    justify-content: end;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
}


.img-show-pop img {
    height: 400px;
    width: 100%;
    margin: auto;
    object-fit: contain;
    background-color: #fff;
    padding: 50px;
    border-radius: 20px;

}

.img-show-pop>div svg {
    background-color: #fff;
    padding: 5px;
    box-sizing: content-box;
    border-radius: 5px;
    cursor: pointer;
}

.pop-trans-set .modal-content {
    background-color: transparent !important;
    border: 0 !important;
    width: 560px;
    margin: auto;
}

.img-show-pop>div button {
    border: 0;
    background-color: transparent;
}



/* driver add page css */

.driver-prof {
    display: flex;
    justify-content: space-between;

}

.profile-image {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 11px;
}

.profile-image h2 {
    font-size: 23px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    margin: 0px;
}

.driver-add p {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    color: #121212;
    margin: 0px;
}

.driver-add p span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #696969;
    margin: 0px;
}

.approve-btn button {
    background: #21387E;
    color: #fff;
    padding: 6px 40px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    min-width: 184px;
}

.approve-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
}

.approve-btn button:last-child {
    background: transparent;
    border: 1px solid #FF0000;
    color: #FF0000;
}

.driver-new-req-det {
    padding: 25px;
}

.documents-add h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #21387E;
    margin: 0px;
}

.documents-add {
    background: #21387E1A;
    padding: 10px;
    border-radius: 4px;
    margin-top: 16px;
}

.driver-documents-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 19px;
}

.documents-count {
    border: 1px solid #E5E5E5;
    text-align: center;
    border-radius: 6px;
    padding: 16px;
}

.documents-count p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #121212;
    margin: 0px;
}

.car-driving-box h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    margin: 0px;
}

.car-driver {
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.car-name p {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #121212;
    margin: 0px;
}

.car-name span {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #696969;
    margin: 0px;
}

.car-name {
    margin-top: 13px;
}

.car-name {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.car-driving-box {
    width: 50%;
    padding: 15px;
    border-radius: 16px;
    background: #fff;
}

.car-owner {
    display: flex;
    align-items: center;
    gap: 10px;
}

.car-owner img {
    width: 39px;
    height: 39px;
    object-fit: cover;
}

.total-earnings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
    gap: 10px;
}

.earnings-text h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    margin: 0px;
}

.earnings-text p {
    font-size: 32px;
    font-weight: 600;
    line-height: 24px;
    color: #21387E;
    margin: 0px;
    padding-top: 13px;
}

.earnings-text {
    background: #fff;
    padding: 41px 0px;
    text-align: center;
    border-radius: 16px;
}

.user-id.locaton p {
    display: flex;
    align-items: center;
    gap: 7px;
}

.locaton {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.review-heading h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    margin: 0px;
    margin-bottom: 12px;
}

.review-heading h3 {
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    color: #696969;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 9px;
}

.reviews {
    margin-top: 20px;
    padding: 20px 20px;
    background: #fff;
    border-radius: 16px;
}


.driver-name-pic img {
    width: 44px;
    height: 44px;
    object-fit: cover;
}

.driver-reviews {
    border: 1px solid #E5E5E5;
    padding: 15px;
    border-radius: 12px;
}

.driver-name-pic h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #121212;
    margin: 0px;
}

.driver-name-pic {
    display: flex;
    gap: 10px;
    align-items: center;
}

p.reviews-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #696969;
    margin-top: 4px;
    margin-bottom: 0px;
}

.driver-filter {
    margin-top: 20px;
}

.driver-page-bg {
    background: #fff;
    border-radius: 16px;
}

.table-trips {
    margin-top: 24px;
    background: #fff;
    padding-bottom: 10px;
    border-radius: 16px;
}

.add-driver-bg-color {
    background: #FAFAFA;
}

/* end */

.image-heading-text button {
    border-radius: 6px;
    border: 1px solid #21387E;
    background: var(--Misc-bg-white, #FFF);
    padding: 7px 18px;
    color: #21387E;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.line-brack p {
    background: #fff;
    width: 100%;
    max-width: 29px;
    height: 27px;
    line-height: 25px;
    border-radius: 50%;
    margin: auto;
    z-index: 1;
    position: relative;
}

.line-brack {
    position: relative;
}

.line-brack:before {
    content: "";
    position: absolute;
    width: 100%;
    background: rgba(229, 229, 229, 1);
    height: 1px;
    left: 0px;
    top: 13px;
}

.add-more-cars-ps {
    width: 100%;
    height: 48px !important;
}